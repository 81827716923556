import React from 'react'
import { Link } from 'gatsby'
import { getConversion } from '../../api/images'

const ServicesTopbar = ({ categories }) => {

    return (
        <div className="row">
            {!!(categories) &&
                <>
                    {categories.map((category) => (
                        <div className="col-lg-3 col-md-6 col-12 mb-3">
                            <div class="card h-100 medias-list">

                                <Link to={`/services?category=${category.slug}`} key={category.id}>
                                    <img src={getConversion(category.media.filter(media => media.collection_name === 'categories')[0])} alt='banner' />
                                    <div>
                                        <h5> {category.name}</h5>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    ))}


                </>
            }



        </div >


    )
}

export default ServicesTopbar