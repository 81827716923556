import React from 'react'
import CategoryCard from './CategoryCard'

const CategoriesList = ({ categories }) => {
    if (!categories) return null

    return (
        <div className="row">
            {categories.map((category) => (
                <CategoryCard
                    key={category.id}
                    category={category}
                />
            ))}
        </div>
    )
}

export default CategoriesList