import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import { navigate } from 'gatsby'
import { getServices } from '../api/service'
import ServicesContent from '../components/Services/ServicesContent'
import { getTime } from '../utils/get-time'
import { getSettings } from '../api/settings'
const Services = ({ location }) => {
    const [categories, setCategories] = useState(null)
    const [page, setPage] = useState(1)
    const [services, setServices] = useState(null)
    const [settings, setSettings] = useState(null)

    const params = new URLSearchParams(location.search)
    const category = params.get('category')
    useEffect(() => {
        (async () => {
           
            if (getTime() > 5 || (!localStorage.getItem('settings') || localStorage.getItem('settings')=== '' || localStorage.getItem('settings') === '[]')) {
                try {
                    setSettings(null)
                    const data = await getSettings()
                    localStorage.setItem('settings', JSON.stringify(data))
                    setTimeout(() => {
                        localStorage.setItem('time', new Date().getTime())
                      }, "15000");
                    setSettings(data)
                } catch (error) {
                    setSettings(null)
                }
               
            } else {
              
                setSettings(JSON.parse(localStorage.getItem('settings')))
            }
        })()
    }, [])
    useEffect(() => {
        (async () => {
            try {
                setCategories(null)
                setServices(null)
                if (category) {
                    const data = await getServices(category, page)
                    setCategories(data.categories)
                    setServices(data.services)
                } else {
                    setPage(1)
                    const data = await getServices(null, null)
                    setCategories(data.categories)
                }
            } catch (error) {
                setCategories(null)
                setPage(1)
                setServices(null)
                navigate('/404')
            }
        })()
    }, [page, category])

    if (!categories && !services) return null

    return (
        <Layout title={settings?.find(x => x.key === 'page.services.title') ? settings.find(x => x.key === 'page.services.title').value : 'Servicios'}>
            <PageBanner
                pageTitle={settings?.find(x => x.key === 'page.services.title') ? settings.find(x => x.key === 'page.services.title').value : 'Servicios'}
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={settings?.find(x => x.key === 'page.services.title') ? settings.find(x => x.key === 'page.services.title').value : 'Servicios'}
            />
            <ServicesContent
                categories={categories}
                services={services}
                setPage={setPage}
            />
        </Layout>
    )
}

export default Services