import React from 'react'
import ServicesList from './ServicesList'
import CategoriesList from './CategoriesList'

// Functional components
const ServicesContent = ({ categories,  services, setPage }) => {
    const params = new URLSearchParams(window.location.search)
    const category = params.get('category')
    return (
        <div className='services-area ptb-50'>
            <div className='container'>
                {category ?
                    <ServicesList
                        categories={categories}
                        setPage={setPage}
                        services={services}
                    />
                    :
                    <CategoriesList
                        categories={categories}
                    />
                }
            </div>
        </div>
    )
}

export default ServicesContent