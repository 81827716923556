import React from 'react'
import { Link } from 'gatsby'
import course1 from '../../assets/images/courses/course1.jpg'
import { format } from 'date-fns'

const ServiceCard = ({ service }) => {
    const formatter = new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })
    const renderPrice = (service) => {
        switch (service.price_type) {
            case "fixed":
                return <>
                    <div className='price shadow'>{service.price == 0 ? 'Gratis' : formatter.format(service.price)}</div>

                </>;
            case "free":
                return <>
                    &nbsp;
                </>;
            case "percentage":
                return <>
                    <div className='price shadow'>{service.price} %</div>

                </>;
            default:
                return <>
                    <div className='price shadow'>{formatter.format(service.price)}</div>
                </>;
        }
    };
    return (
        <div className='col-12 col-md-4'>
            <div className='single-services-box'>
                <div className='services-image'>
                    <Link to={`/service-details?slug=${service.slug}`} className='d-block image'>
                        <img src={service.media[0] ? service.media[0].original_url : course1} alt='about' />
                    </Link>
                    <Link to={`/service-details?slug=${service.slug}`} className='fav'>
                        <i className='flaticon-heart'></i>
                    </Link>
                    {renderPrice(service)}
                </div>
                <div className='services-content'>
                    <h3>
                        <Link to={`/service-details?slug=${service.slug}`}>
                            {service.name}
                        </Link>
                    </h3>
                    <p>{service.summary}</p>
                    <ul className='services-box-footer d-flex justify-content-between align-items-center'>
                        <li>
                            <i className='flaticon-agendas'></i> {format(new Date(service.start_date), 'dd/MM/yyyy')}


                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard