import React from 'react'
import ServiceCard from './ServiceCard'
import ServicesTopbar from './ServicesTopbar'
import Pagination from 'react-js-pagination'

const ServicesList = ({ categories,  services }) => {

    if (!services && !categories) return null

    return (
        <div className='row'>
            <div className='col-lg-12 col-md-12'>
                <div className='service-topbar'>
                    <div className='content'>
                        {categories ?
                            <ServicesTopbar
                                categories={categories}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                <div className='row'>
                    {services?.length > 0 ?
                        services.map((service) => {
                            return (
                                <ServiceCard
                                    key={service.id}
                                    service={service}
                                />
                            )
                        })
                        :
                        <p>Actualmente, no hay servicios disponibles.</p>
                    }
                </div>
            </div>

        </div>
    )
}

export default ServicesList