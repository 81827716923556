import React from 'react'
import { Link } from 'gatsby'
import project1 from '../../assets/images/projects/project1.jpg'

const CategoryCard = ({ category }) => {
    const { description, media, name, slug } = category
    return (
        <div className="col-12 col-lg-3 col-md-6">
            <Link to={`/services?category=${slug}`} className="link-btn">
                <div className="single-projects-box">
                    <div className="image">
                        <img src={media[0] ? media[0].original_url : project1} alt={name} />
                        <div className="link-btn">
                            <i className='bx bx-plus'></i>
                        </div>
                    </div>
                    <div className="content">
                        <h3>{name}</h3>
                        {description ?
                            <span>{description}</span>
                            :
                            null
                        }
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default CategoryCard